<template>
  <div :class="['CEESAR-Toast', isShown? 'CEESAR-Toast-Visible' : 'CEESAR-Toast-Hidden', type == 'error' ? 'CEESAR-Toast-Error' : '']">
      <div class="CEESAR-ToastLabel">{{label}}</div>
      <div class="CEESAR-ToastMessage">{{message}}</div>
  </div>
</template>

<script>
export default {
    name:"Toast",
    data(){
        return{
            isShown: false,
            label: "",
            message: "",
            type: "info",
            timerCount: 0
        };
    },
    created(){
        this.$ceesar.design.registerShowToastEvent(this.Show);
    },
    beforeDestroy(){
        this.$ceesar.design.unregisterShowToastEvent();
    },
    watch:{
        timerCount: {
            handler(value){
                if (value > 0){
                    setTimeout(() => {
                        this.timerCount --;
                        if(this.timerCount < 1) this.isShown = false;
                    }, 1000);
                }
            }
        }
    },
    methods:{
        Show(pMessage, pType = 'info', pTimerCount = 4){
            this.label = "Information";
            if(pType == 'error') this.label = "Error";

            this.message = pMessage;
            this.type = pType;
            this.isShown = true;
            this.timerCount = pTimerCount;
        },
        Hide(){
            this.isShown = false;
        }
    }
}
</script>

<style>

</style>